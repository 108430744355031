/* FONTS */
/* Brandon Font Imports */
/* @font-face {
  font-family: Brandon-regular;
  src: url(./fonts/Brandon_reg.otf);
}

@font-face {
  font-family: Brandon-medium;
  src: url(./fonts/Brandon_med.otf);
}

@font-face {
  font-family: Brandon-light;
  src: url(./fonts/Brandon_light.otf);
}

@font-face {
  font-family: Brandon-bold;
  src: url(./fonts/Brandon_bld.otf);
} */

:root {
  /* --custom-font-1 : 'Work Sans', sans-serif; */
  /* --custom-font-1: "brandon-grotesque", sans-serif; */

  --main-bg-color: #2e4f9e;
  --foregroud-color: #fff;
  --accent-color: #f48269;
  --washed-accent-color: #f59f8c;
  --max-width: 1100px;
  --mobile-breakpoint: 800px;
}

@media only screen and (max-width: 800px) {
  :root {
    font-size: 0.8rem;
  }
}

h1 {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: normal;
  font-size: 6.313em;
  letter-spacing: -1.5;
  line-height: 1em;
}

h2 {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 3.75em;
  line-height: 1;
  letter-spacing: -0.5;
}

h3 {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 3em;
  letter-spacing: 0;
  line-height: 1;
}

h4 {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 600;
  font-size: 2.125em;
  letter-spacing: 0.25;
}

h5 {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.5em;
  letter-spacing: 0;
  color: var(--text-color-1);
}

h6 {
  font-weight: 500;
  font-size: 1.25em;
  letter-spacing: 0.15;
}

body {
  font-family: "brandon-grotesque", sans-serif;
  background-color: var(--main-bg-color);
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: 0.5;
  color: white;
}

body2 {
  font-size: 0.875em;
  font-weight: 400;
  letter-spacing: 0.25;
}

subheading1 {
  font-size: 1em;
  font-weight: 400;
}

subheading2 {
  font-size: 0.875em;
  font-weight: 400;
}

button {
  background-color: var(--accent-color);
  color: var(--main-bg-color);
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1em;
  font-weight: 500;

  border: 2px solid var(--accent-color);
  padding: 0.4em 1.6em;
  border-radius: 2em;
  margin: 2em 0;
}

button:hover {
  border: 2px solid white;
  background-color: white;
}

.navbar {
  background-color: var(--accent-color);
  height: 4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2em;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  position: relative;
  z-index: 2;
}

.container {
  max-width: var(--max-width);
  margin: 1em auto;
  padding: 0 2em;
}

.snapping-container {
  max-width: var(--max-width);
  margin: 4em auto 0 auto;
  padding: 0 2em;
}

@media only screen and (max-width: 800px) {
  .snapping-container {
    margin: 0;
    padding: 0;
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
}

/* landing */

.landing {
  background-color: var(--accent-color);
  margin: 0;
  padding: 5em 0 1em 0;
}

.hero-logo {
  max-width: 300px;
  margin: 0 auto;
  padding: 0 5em 3em 5em;
}

.hero-logo img {
  object-fit: contain;
  width: 100%;
}

.hero-img {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 2em;
}

.hero-img img {
  object-fit: contain;
  width: 100%;
}

.hero-text {
  max-width: 500px;
  margin: 2em auto 1em auto;
  padding: 0 2em;
  text-align: center;
  font-size: 1.1em;
}

/* user registration */
.user-info form {
  /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2.2em;
    grid-row-gap : 0.8em; */
  display: block;
}

@media only screen and (max-width: 800px) {
  .user-info form {
    display: block;
  }
}

.form-element {
  margin-top: 1.2em;
}

label {
  font-size: 1em;
  font-family: var(--custom-font-1);
  font-weight: 500;
}

.user-info input[type="text"],
[type="date"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: var(--custom-font-1);
  background-color: var(--foregroud-color);
  font-size: 0.8em;
}

.start-btn {
  font-family: brandon-medium;
  margin-top: 2em;
  padding: 0.5em 1em;
  background-color: var(--main-bg-color);
  border: 2px solid var(--main-bg-color);
  color: var(--accent-color);
  border-radius: 2em;
  cursor: pointer;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
}

.start-btn:hover {
  background-color: var(--accent-color);
  border: 2px solid var(--main-bg-color);
  color: var(--main-bg-color);
}

/* question part */
.option-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.2em;
  grid-row-gap: 1.2em;
}

.question-wrapper {
  background-color: var(--accent-color);
  padding: 2em 2em;
}

.question-wrapper h4 {
  margin: 0;
  line-height: 1.2;
}

.options-img-wrapper {
  padding: 2.5em 1.5em;
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  grid-column-gap: 2em;
}

.question-img {
  width: 100%;
}

.question-img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.option-element {
  background-color: none;
  color: white;
  font-size: 1.063em;
  padding: 0.5em 1em;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border: 2px solid white;
  border-radius: 2em;
  box-sizing: border-box;
}

.option-element:hover {
  background-color: white;
  color: var(--main-bg-color);
}

/* mobile devices options div*/
@media only screen and (max-width: 800px) {
  .options-img-wrapper {
    padding: 2.5em 0;
    display: block;
  }

  .question-img {
    margin-top: 2em;
    width: 100%;
  }

  .option-wrapper {
    display: block;
  }

  .option-element {
    margin-bottom: 1em;
  }
}

/* results */

.result-card-wrapper {
  background-color: var(--main-bg-color);
  padding: 0 1.5em;
}

.result-card {
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  background-color: var(--accent-color);
  border-radius: 0.5em;
  box-sizing: border-box;
  padding: 2em;
  margin-bottom: 3em;
  align-items: center;
}

.result-card {
  text-align: center;
}

.result-card img {
  width: 100%;
  max-width: 700px;
  object-fit: contain;
}

.dropdown-body {
  text-align: left;
}

.dropdown-btn {
  background-color: white;
  width: fit-content;
  color: black;
  padding: 0.5em 1.5em;
  border-radius: 2em;
  margin: 1em 0;
  cursor: pointer;
}

.button-belt {
  display: flex;
  justify-content: space-between;
}

.working-card-individual {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  max-width: var(--max-width);
  background-color: var(--accent-color);
  border-radius: 0.5em;
  box-sizing: border-box;
  padding: 1em 2em 1.8em 2em;
  gap: 2em;
}

@media only screen and (max-width: 800px) {
  .working-card-individual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: var(--max-width);
    background-color: var(--accent-color);
    border-radius: 0.5em;
    box-sizing: border-box;
    padding: 1em 2em 1.8em 2em;
    gap: 2em;
  }
}

.footer-wrapper {
  background-color: var(--accent-color);
  width: 100%;
  padding: 1.5em 0;
}

.footer {
  max-width: var(--max-width);
  margin: 0 auto;
  background-color: var(--accent-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  max-width: 200px;
  object-fit: contain;
  padding: 1em;
}

.footer li {
  list-style: none;
  margin: 0.2em 1em;
}

.footer .link-top {
  display: flex;
  justify-content: flex-end;
}

.footer .link-bottom {
  display: flex;
}

@media only screen and (max-width: 800px) {
  .footer {
    flex-direction: column;
  }

  .footer li {
    margin: 0.1em 0.5em;
  }

  .footer .link-top {
    display: flex;
    justify-content: center;
  }

  .footer .link-top {
    display: flex;
    justify-content: center;
  }
}

.chart-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
}

@media only screen and (max-width: 800px) {
  .chart-wrapper {
    display: block;
  }
}

/* Animation */
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}
