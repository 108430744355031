.dropdown-body{
    display: none;
}

.open{
    display: block;
}

.icon {
    
    margin-left: 0.5em;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
  }
  
  .icon.open {
    transform: rotate(180deg);
  }